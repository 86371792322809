export const deviceHeight = window.innerHeight;
export const deviceWidth = window.innerWidth;

export default {
  buttonRow: {
    marginLeft: 15,
    marginRight: 15,
    paddingTop: 25,
  },
  mainTxt: {
    textAlign: 'center',
    paddingTop: 15,
    marginLeft: 25,
    marginRight: 25,
    fontSize: 16,
  },
  subTxt: {
    color: '#ff8b00',
    textAlign: 'center',
    paddingTop: 15,
    marginLeft: 25,
    marginRight: 25,
    fontSize: 16,
  },
  imageCol: {
    textAlign: 'center',
  },
  image: {
    // flex: 1,
    height: deviceHeight / 3,
    resizeMode: 'contain',
  },
  imageSmall: {
    // flex: 1,
    height: deviceHeight / 4,
    resizeMode: 'contain',
  },
  video: {
    width: deviceWidth - 100,
  }
};
