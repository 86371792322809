import type {Action} from './types';
import Api from '../services/api';
import Merge from '../helpers/merge';
import {StatModelInitialState} from '../models/statistics';

export const SET_STATISTICS_DATA = 'SET_STATISTICS_DATA';

// get item stat info
export function getStatistics() {
  console.log('statistics::getStatistics');

  return async (dispatch, getState) => {
    // get current states
    const state = getState();
    // build body for API
    const bodyString = {
      uuid: state.item.itemData.item.uuid,
      verificationId: state.item.itemData.verificationId,
      gps: state.app.appLatLng,
      applicationId: state.app.appID,
    };
    // API request
    const result = await Api.getStatistics(bodyString);
    return result;
  };
}

// set item stat data
export function setStatisticsData(statisticsData: object): Action {
  console.log('statistics::setStatisticsData:', statisticsData);

  // map requested data to our object, object based on model
  let data = Merge.deep(StatModelInitialState, statisticsData);
  console.log('statistics::setStatisticsData:merged:', data);

  return {
    type: SET_STATISTICS_DATA,
    statisticsData: data,
  };
}
