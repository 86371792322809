import PropTypes from 'prop-types';
import type {Action} from '../actions/types';

import ConfigConstants from '../constants/config';

import {
  SET_APP_ID,
  SET_APP_LATLNG,
  SET_APP_QR_CAMERA_STATUS,
} from '../actions/app';

// var types
export type State = {
  appID: PropTypes.string,
  appLatLng: PropTypes.string,
  appIsLoading: PropTypes.bool,
  qrCameraStatus: PropTypes.bool,
};

// init states
const initialState = {
  appID: ConfigConstants.APP_ID,
  appLatLng: '',
  appIsLoading: false,
  qrCameraStatus: true,
};

// action reducer
export default function(state: State = initialState, action: Action): State {
  switch (action.type) {
    case SET_APP_ID:
      return {
        ...state,
        appID: action.appID,
      };
    case SET_APP_LATLNG:
      return {
        ...state,
        appLatLng: action.appLatLng,
      };
    case SET_APP_QR_CAMERA_STATUS:
      return {
        ...state,
        qrCameraStatus: action.qrCameraStatus,
      };
  }
  return state;
}
