import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Barcode from 'react-barcode';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {
  Container, Row, Col, Spinner, ListGroup, ListGroupItem, Alert, Button,
} from 'reactstrap';
import { Slide } from 'react-slideshow-image';

import styles from './styles';

import {
  setItemData,
  fetchItemData,
  setItemImages,
  fetchItemTemplate,
} from '../../actions/item';
import {fetchProductData,fetchProductMediaLinks} from '../../actions/product';
import {getStatistics, setStatisticsData} from '../../actions/statistics';
import {
  updateVerification,
  setVerificationRoute,
} from '../../actions/verification';
import {fetchImageData} from '../../actions/image';
import {setAppLatLng} from '../../actions/app';

import ErrorConstants from '../../constants/error';
import {
  VerificationStatus,
  VerificationRoute,
  VerificationIssuesList,
} from '../../constants/verification';
import Translate from '../../helpers/translate';
import DateUtil from '../../helpers/dateUtil';
import Gps from '../../helpers/gps';

import Error from '../../helpers/error';

class ItemInfo extends Component {
  static propTypes = {
    itemData: PropTypes.object,
    statisticsData: PropTypes.object,
    setItemData: PropTypes.func,
    fetchItemData: PropTypes.func,
    fetchItemTemplate: PropTypes.func,
    fetchProductData: PropTypes.func,
    fetchProductMediaLinks: PropTypes.func,
    getStatistics: PropTypes.func,
    setStatisticsData: PropTypes.func,
    fetchImageData: PropTypes.func,
    setItemImages: PropTypes.func,
    updateVerification: PropTypes.func,
    setVerificationRoute: PropTypes.func,
    uuid: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.infoData = [];
    this.videoURL = [];
    this.state = {
      loading: false, // content is loading, not refreshing
      error: false, // error occured ?
      images: [], // images for product
      imageLoading: false, // image loading flag
      infoDataSource: this.infoData, // store
      itemTemplate: '',
    };
  }

  // lifecycle
  componentDidMount() {
    document.title = Translate.TITLE_SCAN_RESULT;
    this.setState({loading: true});
    this.setGPSPosition(this.fetchData);
  }

  // fetch data from API
  fetchData = async () => {
    const { match: { params } } = this.props;

    // image ids from product and item data
    let itemImages = [];

    // fetch it
    try {
      this.itemData = await this.props.fetchItemData(params.uuid);
      // we need VerificationId anyway
      // and in this case if item is not found, item from response body should == null
      // and then we just rise error
      if (this.itemData.item == null) {
        throw new Error(ErrorConstants.ITEM_NOT_FOUND_CODE);
      }
      await this.props.setItemData(this.itemData);

      //get html template
      let itemTemplate = '';
      try {
        itemTemplate = await this.props.fetchItemTemplate(this.itemData.item.id);
        this.setState({itemTemplate: itemTemplate});
      } catch (e) {
        // do nothing
      }

      // get product data
      let productDataImages = [];
      try {
        const productData = await this.props.fetchProductData(
          this.itemData.item.productId,
        );
        productDataImages = productData.images ? productData.images : [];
        this.videoURL = await this.props.fetchProductMediaLinks(this.itemData.item.productId);
      } catch (e) {}

      // one array for images from items and products
      // and remove duplicates
      itemImages = Array.from(
        new Set(
          productDataImages.concat(
            this.itemData.item.images
              ? this.itemData.item.images
              : [],
          ),
        ),
      );

      // add info to view
      this.populateInfoData();

      // init verification
      await this.props.updateVerification(VerificationStatus.INITIALIZED);

      this.setState({error: false, loading: false});
    } catch (e) {
      console.log(e);
      // our QR with bad UUID
      this.setState({error: true, loading: false});
    }

    // try to load images
    let images = [];

    if (itemImages.length) {
      this.setState({imageLoading: true});

      for (let i = 0; i < itemImages.length; i++) {
        // fetch image data
        try {
          let object = await this.props.fetchImageData(itemImages[i]);
          images.push({url: object.data});
        } catch (e) {
          // do nothing
        }
      }

      this.setState({images: images}, () => {
        setTimeout(() => {
          // do little timeout for nice UI
          this.setState({imageLoading: false});
        }, 1000);
      });
    }
    this.props.setItemImages(images);

    return true;
  }

  setGPSPosition = (callback) => {
    const location = window.navigator && window.navigator.geolocation
    if (location) {
      location.getCurrentPosition(async (position) => {
        this.props.setAppLatLng(
          position.coords.latitude.toString() + ',' + position.coords.longitude.toString(),
        );
        await callback();
      }, async (error) => {
        await callback();
      }, {
        enableHighAccuracy: true,
        maximumAge        : 0,
        timeout           : 5000
      });
    }
  }

  populateInfoData = () => {
    this.infoData = [
      {
        text: Translate.TEXT_OBJECT.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
      },
      {
        text: this.itemData.item.name,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
      },
      {
        text: Translate.TEXT_COUNTRY_ORIGIN.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
      },
      {
        text: this.itemData.item.country,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
      },
      {
        text: Translate.TEXT_DEPARTMENT.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
      },
      {
        text: `${this.itemData.department.name} ${
          this.itemData.department.address
        }`,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
      },
      {
        text: Translate.TEXT_MANUFACTURED_DATE.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
      },
      {
        text: this.itemData.item.manufacturedDate,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
      },
    ];
    let itemInfo = {};
    try {
      itemInfo = JSON.parse(this.itemData.item.info);
    } catch (e) {
      // do nothing!!!
    }
    // extended info
    if (Object.keys(itemInfo).length > 0) {
      for (let key in itemInfo) {
        this.infoData.push(
          {
            text: key.toUpperCase(),
            listStyle: styles.containerTitle,
            textStyle: styles.txtTitle,
          },
          {
            text: itemInfo[key],
            listStyle: styles.containerItem,
            textStyle: styles.txtItem,
          },
        );
      }
    }
    // barcode
    if (this.itemData.item.barcode) {
      this.infoData.push(
        {
          text: Translate.TEXT_BARCODE.toUpperCase(),
          listStyle: styles.containerTitle,
          textStyle: styles.txtTitle,
        },
        {
          element: (
            <Barcode
              value={this.itemData.item.barcode}
              format="CODE128"
              background={'transparent'}
            />
          ),
          listStyle: styles.containerItem,
          textStyle: styles.txtItem,
        },
      );
    }
    this.setState({
      infoDataSource: this.infoData,
    });
  }

  // ask user if gps/app/date is same
  algorithmAlertGpsAndDateAndAppIsSame = async () => {
    const result = window.confirm(Translate.ALERT_QUESTION_SCAN_ITEM_ALREADY_TEXT);
    if (result) {
      // update verification route
      this.verificationRoute = this.verificationRoute.ITEM_SCAN_ALREADY.true;

      // in facts it's a 3rd time when we scanned but id DB scan sarts from 0
      if (this.props.statisticsData.todayVerificationsQuantity > 2) {
        // more then 3 checks

        // set route number
        this.props.setVerificationRoute(
          this.verificationRoute.MORE_THEN_3RD_CHECK.true,
        );

        await this.props.updateVerification(
          VerificationStatus.POSSIBLE_COUNTERFEIT,
        );
        const params = {
          text: [
            Translate.INFO_MAYBE_COUNTERFEIT_ITEM_CHECK_MORE_3_TIMES_TEXT,
          ],
          showSendPhoto: true,
          showContactUs: true,
          videoURL: this.videoURL,
        }; //screen 13 //
        this.props.history.replace('/origin-info', params);

      } else {
        // set route number
        this.props.setVerificationRoute(
          this.verificationRoute.MORE_THEN_3RD_CHECK.false,
        );

        await this.props.updateVerification(VerificationStatus.ORIGINAL);
        this.actionIsOriginal(); // screen 15
      }
    } else {
      this.props.setVerificationRoute(
        this.verificationRoute.ITEM_SCAN_ALREADY.false,
      );
      const params = {
        text: [
          Translate.INFO_PROBABLE_COUNTERFEIT_ITEMS_TEXT_TITLE,
          Translate.INFO_PROBABLE_COUNTERFEIT_ITEMS_TEXT_QRS,
        ],
        showScan: true,
        image: 'alert',
        videoURL: this.videoURL,
      };
      this.props.history.replace('/origin-info', params);
    }
  }

  // action if item is original
  actionIsOriginal = () => {
    const params = {
      text: [Translate.INFO_ORIGINAL_ITEM_TEXT],
      image: 'original',
      showDetails: true,
      showContactUs: true,
      videoURL: this.videoURL,
    }; // screen 15 //
    this.props.history.replace('/origin-info', params);
  }

  // action if item maybe COUNTERFEIT
  actionMaybeIsCounterfeit = async () => {
    const params = {
      text: [
        Translate.INFO_PROBABLE_COUNTERFEIT_ITEMS_TEXT_TITLE,
        Translate.INFO_PROBABLE_COUNTERFEIT_ITEMS_TEXT,
      ],
      showSendPhoto: true,
      image: 'alert',
      videoURL: this.videoURL,
    }; // screen 10 //
    this.props.history.replace('/origin-info', params);
  }

  // action if item is original
  actionIsOriginal = async () => {
    const params = {
      text: [Translate.INFO_ORIGINAL_ITEM_TEXT],
      image: 'original',
      showDetails: true,
      showContactUs: true,
      videoURL: this.videoURL,
    }; // screen 15 //
    this.props.history.replace('/origin-info', params);
  }

  // ask user if gps is not same
  algorithmAlertGpsIsNotSame = async (alertText) => {
    const result = window.confirm(alertText);
    if (result) {
      // update verification route
      this.props.setVerificationRoute(
        this.verificationRoute.ITEM_IS_BOUGHT.true,
      );
      await this.props.updateVerification(VerificationStatus.ORIGINAL);
      this.actionIsOriginal(); // screen 15
    } else {
      this.props.setVerificationRoute(
        this.verificationRoute.ITEM_IS_BOUGHT.false,
      );
      const dateLocal = DateUtil.toLocalDate(
        this.props.statisticsData.lastVerificationTimestamp,
      );
      const coords = Gps.getCoordsFromString(
        this.props.statisticsData.lastVerificationGps,
      );
      let gpsString = '';
      if (coords !== false) {
        gpsString = `${Translate.TEXT_GPS_COORD} ${coords.latitude},${
          coords.longitude
        }`;
      }
      const params = {
        text: [
          `${Translate.INFO_MAYBE_COUNTERFEIT_ITEM_TEXT_WITH_DATE} \
        ${dateLocal} ${gpsString}`,
        ],
        image: 'alert',
        showSendPhoto: true,
        showContactUs: true,
        videoURL: this.videoURL,
      };
      this.props.history.replace('/origin-info', params);
    }
  }

  algorithmProcess = async () => {
    // update verification route
    this.verificationRoute = VerificationRoute.DEFAULT;

    // if MANUFACTUREand DEPARTURE transactions is not present in DB
    if (
      !this.props.statisticsData.itemManufactured ||
      !this.props.statisticsData.itemDeparted ||
      this.props.statisticsData.itemDismissed
    ) {
      await this.props.updateVerification(
        VerificationStatus.COUNTERFEIT_NO_TRANSACTIONS,
      );
      const params = {
        text: [
          Translate.INFO_PROBABLE_COUNTERFEIT_ITEM_TEXT,
          Translate.INFO_PROBABLE_COUNTERFEIT_ITEM_TEXT_SEND_PHOTO,
        ],
        showSendPhoto: true,
        image: 'alert',
        videoURL: this.videoURL,
      };
      this.props.history.replace('/origin-info', params);
    } else {
      const objectScannedFirstTime =
        this.props.statisticsData.verificationsQuantity > 0 ? false : true;

      if (objectScannedFirstTime) {
        // scan first time

        await this.props.updateVerification(VerificationStatus.ORIGINAL);
        this.actionIsOriginal(); // screen 15
      } else if (
        this.props.statisticsData.allVerificationsWithinTolerableRadius
      ) {
        // gps is same
        // update verification route
        this.verificationRoute =
          VerificationRoute.SCAN_FIRST_TIME.false.GPS_IS_SAME.true;

        // currently dataOfCheckSame props of statisticsData is not used
        // so we can check if it's same date of scan by checksForToday props

        // lets check if check date is same: item scanned more then 1 time
        if (this.props.statisticsData.todayVerificationsQuantity > 0) {
          // scan date same

          // update verification route
          this.verificationRoute = this.verificationRoute.DATE_IS_SAME.true;

          if (this.props.statisticsData.verifiedByApplicationId) {
            // same app scan

            // update verification route
            this.verificationRoute = this.verificationRoute.APP_IS_SAME.true;

            this.algorithmAlertGpsAndDateAndAppIsSame();
          } else {
            // app is not same

            // in facts it's a 5th time when we scanned but id DB scan sarts from 0
            if (this.props.statisticsData.todayVerificationsQuantity > 4) {
              // more then 5 checks
              this.actionMaybeIsCounterfeit(); // screen 10 //
            } else {
              await this.props.updateVerification(VerificationStatus.ORIGINAL);
              this.actionIsOriginal(); // screen 15
            }
          }
        } else {
          // date is not same

          // update verification route
          this.verificationRoute = this.verificationRoute.DATE_IS_SAME.false;

          if (this.props.statisticsData.verifiedByApplicationId) {
            // same app

            // update verification route
            this.verificationRoute = this.verificationRoute.APP_IS_SAME.true;

            // in facts it's a 10th time when we scanned but id DB scan sarts from 0
            if (this.props.statisticsData.verificationsQuantity > 9) {
              // more then 10 checks
              // update verification route
              this.verificationRoute = this.verificationRoute.MORE_THEN_10TH_CHECK.true;

              this.actionMaybeIsCounterfeit(); // screen 10 //
            } else {
              // update verification route
              this.verificationRoute = this.verificationRoute.MORE_THEN_10TH_CHECK.false;

              await this.props.updateVerification(VerificationStatus.ORIGINAL);
              this.actionIsOriginal(); // screen 15
            }
          } else {
            // app is not same

            // update verification route
            this.verificationRoute = this.verificationRoute.APP_IS_SAME.false;

            // in facts it's a 20th time when we scanned but id DB scan sarts from 0
            if (this.props.statisticsData.verificationsQuantity > 19) {
              // more then 20 checks
              // update verification route
              this.verificationRoute = this.verificationRoute.MORE_THEN_20TH_CHECK.true;

              this.actionMaybeIsCounterfeit(); // screen 10 //
            } else {
              // update verification route
              this.verificationRoute = this.verificationRoute.MORE_THEN_20TH_CHECK.false;

              await this.props.updateVerification(VerificationStatus.ORIGINAL);
              this.actionIsOriginal(); // screen 15
            }
          }
        }
      } else {
        // gps is not same

        // update verification route
        this.verificationRoute =
          VerificationRoute.SCAN_FIRST_TIME.false.GPS_IS_SAME.false.DATE_IS_SAME.false;

        if (this.props.statisticsData.verifiedByApplicationId) {
          // app is same
          // update verification route
          this.verificationRoute = this.verificationRoute.APP_IS_SAME.true;

          this.algorithmAlertGpsIsNotSame(
            Translate.ALERT_DID_YOU_BUY_ITEM_TEXT,
          );
        } else {
          // app is not same
          // update verification route
          this.verificationRoute = this.verificationRoute.APP_IS_SAME.false;

          this.algorithmAlertGpsIsNotSame(
            Translate.ALERT_DID_YOU_OR_FRIEND_BUY_ITEM_TEXT,
          );
        }
      }
    }
  }

  isNotOriginal = () => {
    this.props.history.replace('/contact-us', {issue: VerificationIssuesList.FOTO_NOT_EQUAL});
  }

  originalItem = async () => {
    this.setState({loading: true}, async () => {
      // start the process
      try {
        // check for origin API call
        const statisticsData = await this.props.getStatistics(); // check by API
        this.props.setStatisticsData(statisticsData); // data

        this.setState({loading: false}, () => {
          setTimeout(() => {
            // process origin data
            this.algorithmProcess();
          }, 350);
        });

      } catch (e) {
        this.setState({loading: false}, () => {
          setTimeout(() => {
            this.errorAlert(); // show error alert
          }, 350); // use little timeout, otherwise UI have a mess
        });
      }
    });
  }

  errorAlert = () => {
    alert(Translate.ALERT_ERROR_TEXT);
  }

  // render main content
  renderContent = () => {
    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: true,
      autoplay: false,
    }
    if (!this.state.error) {
      return (
        <Col>
          <Row style={styles.title}>
            <h3>{Translate.TEXT_ITEM_IS_SAME}</h3>
          </Row>
          <Row>
            <Col style={styles.loading}>
            {this.state.imageLoading && (<Spinner animation="grow" color="primary" />)}
            {!this.state.imageLoading && this.state.images.length > 0 && (
              <Slide {...properties}>
              {this.state.images.map((each, index) => <img key={index} style={styles.imageSlide} src={each.url} alt=""/>)}
              </Slide>
            )}
            </Col>
          </Row>
          <ListGroup>
          {this.state.infoDataSource.map((info, index) => {
            const element = info.element ? info.element : <span style={info.textStyle}>{info.text}</span>;
            return <ListGroupItem key={index} style={info.listStyle}>{element}</ListGroupItem>
          })}
          </ListGroup>
          <Row style={styles.containerItem}>
            <Col>
              <Button block color="success" onClick={() => this.originalItem()}>{Translate.TEXT_YES}</Button>
            </Col>
            <Col>
              <Button block color="danger" onClick={() => this.isNotOriginal()}>{Translate.TEXT_NO}</Button>
            </Col>
          </Row>
        </Col>
      );
    } else {
      // if we have error while loading data
      return <Alert color="danger">{Translate.ALERT_ERROR_TEXT}</Alert>
    }
  }

  render() {
    return (
      <Container>
      {this.state.loading && (
        <Col style={styles.loading}>
          <Spinner animation="border" role="status" color="primary" />
        </Col>
      )}
      {!this.state.loading && this.renderContent()}
      </Container>
    );
  }
}

function bindAction(dispatch) {
  return {
    setItemData: itemData => dispatch(setItemData(itemData)),
    setItemImages: images => dispatch(setItemImages(images)),
    setStatisticsData: statisticsData =>
      dispatch(setStatisticsData(statisticsData)),
    fetchItemData: uuid => dispatch(fetchItemData(uuid)),
    fetchItemTemplate: itemId => dispatch(fetchItemTemplate(itemId)),
    fetchProductData: productId => dispatch(fetchProductData(productId)),
    fetchProductMediaLinks: productId => dispatch(fetchProductMediaLinks(productId)),
    getStatistics: () => dispatch(getStatistics()),
    fetchImageData: imageId => dispatch(fetchImageData(imageId)),
    updateVerification: verificationStatus =>
      dispatch(updateVerification(verificationStatus)),
    setVerificationRoute: verificationRoute =>
      dispatch(setVerificationRoute(verificationRoute)),
    setAppLatLng: appLatLng => dispatch(setAppLatLng(appLatLng)),
  };
}

const mapStateToProps = state => ({
  itemData: state.item.itemData,
  statisticsData: state.statistics.statisticsData,
});

export default connect(
  mapStateToProps,
  bindAction,
)(withRouter(ItemInfo));
