import type {Action} from './types';
import Api from '../services/api';
import Merge from '../helpers/merge';
import {ItemModelInitialState} from '../models/item';

export const SET_ITEM_DATA = 'SET_ITEM_DATA';
export const SET_ITEM_IMAGES = 'SET_ITEM_IMAGES';

// transaction types
export const TRANSACTIONS_TYPE = {
  MANUFACTURE: 0,
  DEPARTURE: 1,
  ARRIVAL: 2,
  DISMISSAL: 3,
  REALIZATION: 4,
};

// set item data
export function setItemData(itemData: object): Action {
  console.log('item::setItemData:', itemData);

  // map requested data to our object, object based on model
  let newItemData = Merge.deep(ItemModelInitialState, itemData);
  // need to resort transactions by type
  if (Array.isArray(newItemData.transactions)) {
    // check if transaction exists
    if (newItemData.transactions.length > 0) {
      newItemData.transactions.sort(
        (a, b) => TRANSACTIONS_TYPE[a.type] > TRANSACTIONS_TYPE[b.type],
      );
    }
  }
  console.log('mergedItemData', newItemData);

  return {
    type: SET_ITEM_DATA,
    itemData: newItemData,
  };
}

// set image data of item
export function setItemImages(images: array): Action {
  console.log('item::setItemImages:', images);

  return {
    type: SET_ITEM_IMAGES,
    itemImages: images,
  };
}

// request item data over API
export function fetchItemData(uuid: string) {
  return async (dispatch, getState) => {
    // get current states
    const state = getState();
    console.log("state", state);
    // build body for API
    const bodyString = {
      uuid: uuid,
      gps: state.app.appLatLng,
      applicationId: state.app.appID,
    };
    // API request
    const result = await Api.getItem(bodyString);
    return result;
  };
}

// get item html details
export function fetchItemTemplate(itemId: string) {
  return async (dispatch, getState) => {
    return await Api.getItemTemplate(itemId);
  };
}
