import Api from '../services/api';
import Merge from '../helpers/merge';

import {BarcodeModelInitialState} from '../models/barcode';

// request barcode data over API
export function fetchBarcodeData(barcode: string) {
  return async (dispatch, getState) => {
    const data = await Api.getBarcodeInfo(barcode);
    return Merge.deep(BarcodeModelInitialState, data);
  };
}
