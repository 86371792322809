import Api from '../services/api';

// request image data over API
export function fetchImageData(imageId: string) {
  return async (dispatch, getState) => {
    const result = await Api.getImageById(imageId);
    return result;
  };
}

// save image data over API
export function saveImageData(imageData: object) {
  return async (dispatch, getState) => {
    const bodyString = {
      data: imageData.data,
      itemId: imageData.itemId ? imageData.itemId : null,
      productId: imageData.productId ? imageData.productId : null,
      endUserResponseId: imageData.endUserResponseId
        ? imageData.endUserResponseId
        : null,
    };
    const result = await Api.saveImage(bodyString);
    return result;
  };
}
