import React, { useState, useEffect } from "react";
import {Route, Redirect} from "react-router-dom";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import Translate, {translationGetters} from '../helpers/translate';
import '../css/App.css';

export default function RouteWrapper({
  component: Component,
  isCheckInit,
  ...rest
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    setLoading(false);
  },[loading]);

  const setTranslation = (lng) => {
    setLoading(true);
    Translate.setTranslation(lng);
  }

  if (!isCheckInit) {
    return <Redirect to="/" />;
  }

  return (
    <div className="App">
      <section className="app-top">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="logo-top-wp">
                <span className="navbar-brand"></span>
                <div className="ellipse-top-left"></div>
                <div className="language-selector">
                  <Dropdown isOpen={dropdownOpen} toggle={toggle} className="language">
                    <DropdownToggle color="custom" caret>
                      {Translate.getTranslation().toUpperCase()}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.keys(translationGetters).map(lng =>
                        <DropdownItem key={lng} onClick={() => setTranslation(lng)}>{lng.toUpperCase()}</DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {!loading && <Route {...rest} component={Component} />}
    </div>
  );
}
