export default {
  title: {
    paddingLeft: 25,
    paddingRight: 25,
    textAlign: 'center',
  },
  badge: {
    whiteSpace: 'normal',
  },
  loading: {
    textAlign: 'center',
  }
};
