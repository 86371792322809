import React from 'react';
import {Col, Container} from 'reactstrap';
import styles from './styles';

class NotFound extends React.Component {
  render() {
    return (
      <Container>
        <Col style={styles.title}>
          <h1 style={styles.h1}>Ooops! 404</h1>
        </Col>
      </Container>
    );
  }
}

export default NotFound;
