import ErrorConstants from '../constants/error';
import Config from '../helpers/config';
import Error from '../helpers/error';

const Api = {
  getImageById,
  updateVerification,
  getItem,
  getStatistics,
  getItemTemplate,
  getProductTemplate,
  getProductById,
  getBarcodeInfo,
  getDepartmentById,
  sendCustomerResponse,
  saveImage,
  getProductMediaLinks,
};

// build headers for resquest
function getHeaderOptions(bodyData: object, method: string = 'POST') {
  let requestOptions = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      //'OOAppVersion': 'web-app-1.0.0',
    },
  };
  if (Object.keys(bodyData).length) {
    requestOptions.body = JSON.stringify(bodyData);
  }
  return requestOptions;
}

// process response object
async function responseProcess(response: object) {
  console.log('API::responseProcess', response);
  /*response.headers.forEach(function(value, name) {
      console.log(name + ": " + value);
  })*/
  const contentType = response.headers.get('Content-Type') ? response.headers.get('Content-Type') : "";
  switch (response.status) {
    case 201:
    case 200:
      if (
        contentType.indexOf('application/json') !== -1
      ) {
        try {
          const json = await response.json();
          return json;
        } catch (e) {
          throw new Error(ErrorConstants.GENERIC_ERROR_CODE, e.message);
        }
      } else {
        const text = await response.text();
        return text;
      }
    case 400:
    case 404:
    default:
      let body = null;
      try {
        body = await response.json();
      } catch (e) {
        body = await response.text();
      }
      let code = ErrorConstants.ITEM_NOT_FOUND_CODE;
      if (response.status === 400 || response.status === 404) {
        code = ErrorConstants.ITEM_NOT_FOUND_CODE;
      }
      throw new Error(code, body);
  }
}

// fetch data
async function fetchData(
  path: string,
  options: object,
  apiUrl: string = Config.getJavaApiUrl(),
) {
  console.log('API::fetchData', apiUrl + path, options);
  // get response
  const response = await fetch(apiUrl + path, options);
  // check for errors
  return responseProcess(response);
}
//
// API CALLS
//
// get stats of item
async function getStatistics(bodyData: object) {
  console.log('API::getStatistics', bodyData);

  const requestOptions = getHeaderOptions({}, 'GET');
  const queryString = Object.keys(bodyData)
    .map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(bodyData[key]),
    )
    .join('&');

  return fetchData(`verifications/statistics?${queryString}`, requestOptions);
}

// get item template
async function getProductTemplate(productId: string) {
  console.log('API::getProductTemplate', productId);

  const requestOptions = getHeaderOptions({}, 'GET');
  // get response
  return fetchData(`templates/products/${productId}`, requestOptions);
}
// get item template
async function getItemTemplate(itemId: string) {
  console.log('API::getItemTemplate', itemId);

  const requestOptions = getHeaderOptions({}, 'GET');
  // get response
  return fetchData(`templates/items/${itemId}`, requestOptions);
}

// get item data
async function getItem(bodyData: object) {
  console.log('API::getItem', bodyData);

  const requestOptions = getHeaderOptions(bodyData);
  // get response
  return fetchData('verifications', requestOptions);
}

// update verification
async function updateVerification(verificationId: string, bodyData: object) {
  console.log(`API::updateVerification/${verificationId}`, bodyData);

  const requestOptions = getHeaderOptions(bodyData, 'PATCH');
  return fetchData(`verifications/${verificationId}`, requestOptions);
}
// get product by id
async function getProductById(productId: string) {
  console.log(`API::getProductById/${productId}`);

  const requestOptions = getHeaderOptions({}, 'GET');
  return fetchData(`products/${productId}/non-restricted`, requestOptions);
}
// get product by id
async function getDepartmentById(departmentId: string) {
  console.log(`API::getDepartmentById/${departmentId}`);

  const requestOptions = getHeaderOptions({}, 'GET');
  return fetchData(`departments/${departmentId}`, requestOptions);
}
// get image data by id
async function getImageById(imageId: string) {
  console.log(`API::getImageById/${imageId}`);

  const requestOptions = getHeaderOptions({}, 'GET');

  return fetchData(`images/${imageId}`, requestOptions);
}

async function getBarcodeInfo(barcode: string) {
  console.log(`API::getBarcodeInfo/${barcode}`);

  const requestOptions = getHeaderOptions({}, 'GET');
  return fetchData(`barcode/${barcode}`, requestOptions, Config.getPhpApiUrl());
}

async function getProductMediaLinks(productId: string) {
  console.log(`API::getProductMediaLinks/${productId}`);

  const requestOptions = getHeaderOptions({}, 'GET');
  return fetchData(`products/${productId}/links`, requestOptions, Config.getPhpApiUrl());
}

// send customer response
async function sendCustomerResponse(bodyData: object) {
  console.log('API::sendCustomerResponse', bodyData);

  const requestOptions = getHeaderOptions(bodyData);
  return fetchData('end-user-responses', requestOptions);
}

// save image data
async function saveImage(bodyData: object) {
  console.log('API::saveImage');

  const requestOptions = getHeaderOptions(bodyData);
  return fetchData('images', requestOptions);
}

export default Api;
