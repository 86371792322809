import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, Col, Form, FormGroup, Label, Input, Spinner, Badge, Container, Row} from 'reactstrap';
import Resizer from 'react-image-file-resizer';

import {saveImageData} from '../../actions/image';
import {sendCustomerResponse} from '../../actions/response';

import Translate from '../../helpers/translate';
import styles from './styles';

import {
  updateVerification,
  isVerificationInit,
  setVerificationRoute,
} from '../../actions/verification';
import {
  VerificationStatus,
  VerificationRoute,
  VerificationIssuesList,
} from '../../constants/verification';
import ImageConstants from '../../constants/image';

class ContactUs extends Component {
  static defaultProps = {
    issue: '',
    images: [],
  };

  static propTypes = {
    issue: PropTypes.string,
    verificationRoute: PropTypes.number,
    isVerificationInit: PropTypes.func,
    updateVerification: PropTypes.func,
    setVerificationRoute: PropTypes.func,
    saveImageData: PropTypes.func,
    sendCustomerResponse: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      issue: this.getPreDefinedIssue(), // pre defined issue
      name: '',
      email: '',
      phone: '',
      comment: '',
      image: '',
      nameError: '',
      emailError: '',
      phoneError: '',
    };
  }

  // get predefined issue from route or passed from other component
  getPreDefinedIssue() {
    let resIssue;
    const {history: { state: issue }} = this.props;
    if (issue) {
      // pass issue from other component
      resIssue = issue;
    } else {
      // choose issue by route
      switch (this.props.verificationRoute) {
        case 1:
          resIssue = VerificationIssuesList.DOUBT_FOR_ORIGINALITY; // DOUBT_FOR_ORIGINALITY
          break;
        case 9:
          resIssue = VerificationIssuesList.SAME_CODE_DIFF_ITEM; // SAME_CODE_DIFF_ITEM
          break;
        default:
          // 2 - 8
          resIssue = VerificationIssuesList.SYSTEM_DIRECT; // SYSTEM_DIRECT
          break;
      }
    }
    return resIssue;
  }

  async contactUs() {
    let canProcess = true;
    const {name, phone, email, issue, comment} = this.state;

    // check
    const nameRegExp = /^[a-z\-\s\u0430-\u044f\u0406\u0490]{3,30}$/i;
    if (!name || !nameRegExp.test(name)) {
      this.setState({nameError: Translate.TEXT_ERROR_NAME});
      canProcess = false;
    } else {
      this.setState({nameError: ''});
    }

    const phoneRegExp = /^\+[0-9]{12,15}$/;
    if (!phone || !phoneRegExp.test(phone)) {
      this.setState({phoneError: Translate.TEXT_ERROR_PHONE});
      canProcess = false;
    } else {
      this.setState({phoneError: ''});
    }

    const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!email || !emailRegExp.test(email)) {
      this.setState({emailError: Translate.TEXT_ERROR_EMAIL});
      canProcess = false;
    } else {
      this.setState({emailError: ''});
    }

    if (!issue) {
      this.setState({issueError: Translate.TEXT_ERROR_ISSUE});
      canProcess = false;
    } else {
      this.setState({issueError: ''});
    }

    if (canProcess) {
      this.setState({inProgress: true});

      try {
        // save data
        const data = {
          name,
          phone,
          email,
          issue,
          type: 'MANUAL',
          description: comment,
        };

        const resultResponse = await this.props.sendCustomerResponse(data);
        // save images
        await this.saveImages(resultResponse.id);

        // if verification init but not updated
        // then update it
        if (this.props.isVerificationInit()) {
          await this.props.updateVerification(
            VerificationStatus.ON_VERIFICATION,
          );
        }
        this.props.setVerificationRoute(VerificationRoute.DEFAULT); // back to default

        this.setState({inProgress: false}, () => {
          const params = {
            text: [Translate.ALERT_WAS_RESPONSE_SENT],
            showDetails: false,
            showContactUs: false,
          };
          this.props.history.replace('/origin-info', params);
        });
      } catch (e) {
        console.log(e);

        this.setState({inProgress: false}, () => {
          setTimeout(() => {
            // show alert error
            alert(Translate.ALERT_ERROR_TEXT);
          }, 250);
        });
      }
    }
  }

  // save image data
  async saveImages(endUserResponseId) {
    const {image} = this.state;
    let imageIds = []; // saved image ids
    if (image) {
      const result = await this.props.saveImageData({
        data: image,
        endUserResponseId: endUserResponseId,
      });
      imageIds.push(result.id);
    }
    return imageIds;
  }

  onIssueValueChange(value) {
    this.setState({
      issue: value,
    });
  }

  // when radio is press
  selectRadio(issue) {
    this.setState({issue: issue});
  }

  render() {
    const {inProgress, nameError, phoneError, emailError, issueError} = this.state;
    return (
      <Container>
        <Row style={styles.title}>
          <h3>{Translate.TEXT_CONTACT_US_INFO}</h3>
        </Row>
        <Form>
          <FormGroup>
            <Label for="name">{Translate.LABEL_NAME.toUpperCase()}</Label>
            <Input type="name" name="name" id="name" placeholder="" onChange={e => this.setState({name: e.target.value})}/>
            <Badge color="danger" style={styles.badge}>{nameError}</Badge>
          </FormGroup>
          <FormGroup>
            <Label for="phone">{Translate.LABEL_PHONE.toUpperCase()}</Label>
            <Input type="phone" name="phone" id="phone" placeholder="" onChange={e => this.setState({phone: e.target.value})}/>
            <Badge color="danger" style={styles.badge}>{phoneError}</Badge>
          </FormGroup>
          <FormGroup>
            <Label for="email">{Translate.LABEL_EMAIL.toUpperCase()}</Label>
            <Input type="email" name="email" id="email" placeholder=""  onChange={e => this.setState({email: e.target.value})}/>
            <Badge color="danger" style={styles.badge}>{emailError}</Badge>
          </FormGroup>
          <FormGroup tag="fieldset">
             <Label>{Translate.LABEL_ISSUE.toUpperCase()}</Label>
             {Object.keys(VerificationIssuesList).map(issue_key =>
               <FormGroup key={issue_key} check>
                 <Label check>
                   <Input type="radio" name="issue"
                   checked={this.state.issue === VerificationIssuesList[issue_key]}
                   onChange={() => this.selectRadio(VerificationIssuesList[issue_key])} />{' '}
                   {Translate[`TEXT_ISSUE_${VerificationIssuesList[issue_key]}`]}
                 </Label>
               </FormGroup>
             )}
             <Badge color="danger" style={styles.badge}>{issueError}</Badge>
          </FormGroup>
          <FormGroup>
            <input accept="image/*" type="file" onChange={(e) => {
              Resizer.imageFileResizer(
                e.target.files[0],
                ImageConstants.MAX_WIDTH,
                ImageConstants.MAX_HEIGHT,
                'JPEG',
                100,
                0,
                uri => {
                  this.setState({image: uri});
                },
                'base64'
              );
            }} />
          </FormGroup>
          <FormGroup>
            <Label for="comment">{Translate.LABEL_COMMENT}</Label>
            <Input type="textarea" name="comment" id="comment"  onChange={e => this.setState({comment: e.target.value})}/>
          </FormGroup>
          <Button block disable={inProgress ? 'true' : 'false'} color="success" onClick={async () => await this.contactUs()}>{Translate.TEXT_SENT}</Button>
          {inProgress && (
            <Col style={styles.loading}>
              <Spinner animation="border" role="status" color="primary" />
            </Col>
          )}
        </Form>
      </Container>
    );
  }
}

function bindAction(dispatch) {
  return {
    isVerificationInit: () => dispatch(isVerificationInit()),
    updateVerification: verificationStatus =>
      dispatch(updateVerification(verificationStatus)),
    saveImageData: imageData => dispatch(saveImageData(imageData)),
    sendCustomerResponse: data => dispatch(sendCustomerResponse(data)),
    setVerificationRoute: verificationRoute =>
      dispatch(setVerificationRoute(verificationRoute)),
  };
}

const mapStateToProps = state => ({
  verificationRoute: state.verification.verificationRoute,
});

export default connect(
  mapStateToProps,
  bindAction,
)(withRouter(ContactUs));
