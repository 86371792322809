import {createStore, applyMiddleware, compose} from 'redux';
import {persistStore} from 'redux-persist';
import thunk from 'redux-thunk';
import reducer from './reducers';
import promise from './promise';

export default function configureStore(onCompletion: () => void): any {
  const enhancer = compose(
    applyMiddleware(thunk, promise),
    // for dev mode only
    /*devTools({
      name: 'only-original', realtime: true, port: 8081
    }),*/
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  const store = createStore(reducer, enhancer);
  persistStore(store, null, onCompletion);

  return store;
}
