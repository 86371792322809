import type {Action} from '../actions/types';
import {SET_STATISTICS_DATA} from '../actions/statistics';
import {StatModelState, StatModelInitialState} from '../models/statistics';

// var types
export type State = {
  statisticsData: StatModelState,
};

// init states
const initialState = {
  statisticsData: StatModelInitialState,
};

// action reducer
export default function(state: State = initialState, action: Action): State {
  switch (action.type) {
    case SET_STATISTICS_DATA:
      return {
        ...state,
        statisticsData: action.statisticsData,
      };
  }
  return state;
}
