import Api from '../services/api';
import Merge from '../helpers/merge';
import {ProductModelInitialState} from '../models/product';

export const SET_PRODUCT_MEDIA_LINKS = 'SET_PRODUCT_MEDIA_LINKS';

// request product data over API
export function fetchProductData(productId: string) {
  return async (dispatch, getState) => {
    const data = await Api.getProductById(productId);
    return Merge.deep(ProductModelInitialState, data);
  };
}

// get product html details
export function fetchProductTemplate(productId: string) {
  return async (dispatch, getState) => {
    return await Api.getProductTemplate(productId);
  };
}

// get media links
export function fetchProductMediaLinks(productId: string) {
  return async (dispatch, getState) => {
    const response = await Api.getProductMediaLinks(productId);
    let result = [];
    if (response.length > 0) {
      response.map(data => result.push(data.url));
    }
    return result;
  };
}

// set media links
export function setProductMediaLinks(links: array): Action {
  return {
    type: SET_PRODUCT_MEDIA_LINKS,
    productMediaLinks: links,
  };
}
