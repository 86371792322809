import detectBrowserLanguage from 'detect-browser-language';
import i18n from 'i18n-js';
import {memoize} from 'lodash';

export const translationGetters = {
  en: () => require('../translations/en.json'),
  de: () => require('../translations/de.json'),
  es: () => require('../translations/es.json'),
  uk: () => require('../translations/uk.json'),
  ru: () => require('../translations/ru.json'),
  nl: () => require('../translations/nl.json'),
  fr: () => require('../translations/fr.json'),
  pt: () => require('../translations/pt.json'),
  it: () => require('../translations/it.json'),
};

const translate = memoize(
  (key, config) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key),
);

const findBestAvailableLanguage = (languages) => {
  let languageTag;
  const browserLanguage = detectBrowserLanguage();
  languages.forEach(lng => {
    if(browserLanguage.indexOf(lng) > -1) {
      languageTag = lng;
    }
  });
  return languageTag;
}

const setI18nConfig = (language = '') => {
  // fallback if no available language fits
  let languageTag;
  if (!language) {
    const fallback = 'en';
    languageTag = findBestAvailableLanguage(Object.keys(translationGetters)) || fallback;
  } else {
    languageTag = language;
  }
  // clear translation cache
  translate.cache.clear();
  // update layout direction
  // set i18n-js config
  i18n.translations = {[languageTag]: translationGetters[languageTag]()};
  i18n.locale = languageTag;
};

setI18nConfig();

class TranslateHelper {
  constructor() {
    return new Proxy(this, this);
  }
  get(target, prop) {
    return this[prop] || translate(prop);
  }
  setTranslation(language) {
    setI18nConfig(language);
  }
  getTranslation() {
    return i18n.locale;
  }
}

const Translate = new TranslateHelper();

export default Translate;
