import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {
  Container, Row, Col, ListGroup, ListGroupItem, Button,
} from 'reactstrap';
import { Slide } from 'react-slideshow-image';

import styles from './styles';
import Translate from '../../helpers/translate';
import Gps from '../../helpers/gps';
import DateUtil from '../../helpers/dateUtil';

class ItemInfoExt extends Component {
  static propTypes = {
    itemData: PropTypes.object,
    itemImages: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.infoData = [];
    this.state = {
      infoDataSource: this.infoData, // store
    };
  }

  componentDidUpdate(prevProps) {
    // fetch new data if needed
    if (this.props.itemData.item.idItem !== prevProps.itemData.item.idItem) {
      this.populateData();
    }
  }

  componentDidMount() {
    document.title = Translate.TITLE_SCAN_RESULT;
    this.populateData();
  }

  populateData() {
    this.infoData = [
      {
        text: Translate.TEXT_OBJECT.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
      },
      {
        text: this.props.itemData.item.name,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
      },
      {
        text: Translate.TEXT_COUNTRY_ORIGIN.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
      },
      {
        text: this.props.itemData.item.country,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
      },
      {
        text: Translate.TEXT_DEPARTMENT.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
      },
      {
        text: `${this.props.itemData.department.name} ${
          this.props.itemData.department.address
        }`,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
      },
      {
        text: Translate.TEXT_MANUFACTURED_DATE.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
      },
      {
        text: this.props.itemData.item.manufacturedDate,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
      },
    ];

    if (this.props.itemData.transactions.length > 0) {
      for (let i = 0; i < this.props.itemData.transactions.length; i++) {
        const transactionTitle = `TEXT_TRANSACTION_TYPE_${
          this.props.itemData.transactions[i].type
        }`;
        const transactionDate = DateUtil.toLocalDate(
          this.props.itemData.transactions[i].creationTimestamp,
        );
        const transactionCoords = Gps.getCoordsFromString(
          this.props.itemData.transactions[i].gps,
        );

        if (transactionCoords !== false) {
          const transactionText = `${transactionDate}\n${
            Translate.TEXT_GPS_COORD
          }: ${transactionCoords.latitude},\
          ${transactionCoords.longitude}`;
          this.infoData.push({
            text: Translate[transactionTitle].toUpperCase(),
            listStyle: styles.containerTitle,
            textStyle: styles.txtTitle,
          });
          // todo each type has own unit/address/gps
          this.infoData.push({
            text: transactionText,
            listStyle: styles.containerItem,
            textStyle: styles.txtItem,
          });
        }
      }
    }

    this.setState({
      infoDataSource: this.infoData,
    });
  }

  render() {
    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: true,
      autoplay: false,
    }
    const {itemImages} = this.props;
    return (
      <Container>
        {itemImages.length > 0 && (<Col>
          <Slide {...properties}>
          {itemImages.map((each, index) => <img key={index} style={styles.imageSlide} src={each.url} alt=""/>)}
          </Slide></Col>
        )}
        <ListGroup>
        {this.state.infoDataSource.map((info, index) => {
          const element = info.element ? info.element : <span style={info.textStyle}>{info.text}</span>;
          return <ListGroupItem key={index} style={info.listStyle}>{element}</ListGroupItem>
        })}
        </ListGroup>
        <Row style={styles.containerItem}>
          <Col>
            <Button block color="success" onClick={() => this.props.history.replace('/contact-us')}>{Translate.TEXT_CONTACT_US}</Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

function bindAction(dispatch) {
  return {};
}

const mapStateToProps = state => ({
  itemData: state.item.itemData,
  itemImages: state.item.itemImages,
});

export default connect(
  mapStateToProps,
  bindAction,
)(withRouter(ItemInfoExt));
