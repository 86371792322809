import PropTypes from 'prop-types';

import type {Action} from '../actions/types';
import {SET_ITEM_DATA, SET_ITEM_IMAGES} from '../actions/item';
import {ItemModelState, ItemModelInitialState} from '../models/item';

// var types
export type State = {
  itemData: ItemModelState,
  itemImages: PropTypes.array,
};

// init states
const initialState = {
  itemData: ItemModelInitialState,
  itemImages: [],
};

// action reducer
export default function(state: State = initialState, action: Action): State {
  switch (action.type) {
    case SET_ITEM_DATA:
      return {
        ...state,
        itemData: action.itemData,
      };
    case SET_ITEM_IMAGES:
      return {
        ...state,
        itemImages: action.itemImages,
      };
  }
  return state;
}
