// verification status for all APP
export const VerificationStatus = {
  NA: 'NOT_AVAILABLE',
  INITIALIZED: 'INITIALIZED',
  CANCELED_BY_CUSTOMER: 'CANCELED_BY_CUSTOMER',
  ON_VERIFICATION: 'ON_VERIFICATION',
  COUNTERFEIT_NOT_PRESENT: 'COUNTERFEIT_NOT_PRESENT',
  COUNTERFEIT_NO_TRANSACTIONS: 'COUNTERFEIT_NO_TRANSACTIONS',
  COUNTERFEIT_DUPLICATED: 'COUNTERFEIT_DUPLICATED',
  POSSIBLE_COUNTERFEIT: 'POSSIBLE_COUNTERFEIT',
  ORIGINAL: 'ORIGINAL',
};
// verification status only used when verification updated over API
export const VerificationResultReasonStatus = {
  ORIGINAL: 'ORIGINAL',
  ON_VERIFICATION: 'ON_VERIFICATION',
  CANCELED_BY_CUSTOMER: 'CANCELED_BY_CUSTOMER',
  INITIALIZED: 'INITIALIZED',
  COMPLETED: 'COMPLETED',
  COUNTERFEIT: 'COUNTERFEIT',
  INEXISTENT_ITEM: 'INEXISTENT_ITEM',
  NO_TRANSACTIONS: 'NO_TRANSACTIONS',
  ITEM_DUPLICATED: 'ITEM_DUPLICATED',
  POSSIBLE_COUNTERFEIT: 'POSSIBLE_COUNTERFEIT',
};
// reason for contact us page
export const VerificationIssuesList = {
  FOTO_NOT_EQUAL: 'FOTO_NOT_EQUAL',
  SAME_CODE_DIFF_ITEM: 'SAME_CODE_DIFF_ITEM',
  YOU_OR_YOU_FRIEND_BOUGHT_ITEM: 'YOU_OR_YOU_FRIEND_BOUGHT_ITEM',
  DOUBT_FOR_ORIGINALITY: 'DOUBT_FOR_ORIGINALITY',
  SYSTEM_DIRECT: 'SYSTEM_DIRECT',
  OTHER: 'OTHER',
};

//
// route identifications
//

// default route
const DEFAULT_VERIFICATION_ROUTE = 1;

// verification route
export const VerificationRoute = {
  // TODO: refactoring
  DEFAULT: DEFAULT_VERIFICATION_ROUTE,
  // draw diagram like an object
  SCAN_FIRST_TIME: {
    true: DEFAULT_VERIFICATION_ROUTE,
    false: {
      GPS_IS_SAME: {
        true: {
          // gps is same
          DATE_IS_SAME: {
            true: {
              // date is same
              APP_IS_SAME: {
                true: {
                  ITEM_SCAN_ALREADY: {
                    // did you scan same item ?
                    false: 9,
                    true: {
                      MORE_THEN_3RD_CHECK: {
                        // more then 3rd checks ?
                        true: DEFAULT_VERIFICATION_ROUTE,
                        false: DEFAULT_VERIFICATION_ROUTE,
                      },
                    },
                  },
                },
                false: {
                  MORE_THEN_5TH_CHECK: {
                    // more then 5th checks ?
                    true: 8,
                    false: DEFAULT_VERIFICATION_ROUTE,
                  },
                },
              },
            },
            false: {
              // date is not same
              APP_IS_SAME: {
                true: {
                  MORE_THEN_10TH_CHECK: {
                    true: 7,
                    false: DEFAULT_VERIFICATION_ROUTE,
                  },
                },
                false: {
                  MORE_THEN_20TH_CHECK: {
                    true: 6,
                    false: DEFAULT_VERIFICATION_ROUTE,
                  },
                },
              },
            },
          },
        },
        false: {
          // gps is not same
          DATE_IS_SAME: {
            true: {
              // date is same
              APP_IS_SAME: {
                true: {
                  ITEM_IS_BOUGHT: {
                    false: 5,
                    true: DEFAULT_VERIFICATION_ROUTE,
                  },
                },
                false: {
                  ITEM_IS_BOUGHT: {
                    false: 4,
                    true: DEFAULT_VERIFICATION_ROUTE,
                  },
                },
              },
            },
            false: {
              // date is not same
              APP_IS_SAME: {
                true: {
                  ITEM_IS_BOUGHT: {
                    false: 3,
                    true: DEFAULT_VERIFICATION_ROUTE,
                  },
                },
                false: {
                  ITEM_IS_BOUGHT: {
                    false: 2,
                    true: DEFAULT_VERIFICATION_ROUTE,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
