export default {
  row: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 40,
  },
  col: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  txtTitle: {
    color: '#000',
    fontSize: 12,
  },
  txtItem: {
    fontSize: 16,
  },
  containerTitle: {
    paddingBottom: 5,
  },
  containerItem: {
    paddingTop: 10,
  },
  btn: {
    marginLeft: 15,
    marginRight: 15,
  },
  imageSlide: {
    maxWidth: 320,
  },
  loading: {
    textAlign: 'center',
  },
};
