import type {Action} from './types';

export const SET_APP_ID = 'SET_APP_ID';
export const SET_APP_LATLNG = 'SET_APP_LATLNG';
export const SET_APP_QR_CAMERA_STATUS = 'SET_APP_QR_CAMERA_STATUS';

// set unique APP ID
export function setAppID(appID: string): Action {
  console.log('app::setAppID: ', appID);
  return {
    type: SET_APP_ID,
    appID: appID,
  };
}

// set app GPS coord
export function setAppLatLng(appLatLng: string): Action {
  console.log('app::setAppLatLng: ', appLatLng);
  return {
    type: SET_APP_LATLNG,
    appLatLng: appLatLng,
  };
}

// switch on/ switch off QR code camera globaly
export function setAppQRCameraStatus(qrCameraStatus: boolean): Action {
  console.log('app::setAppQRCameraStatus: ', qrCameraStatus);
  return {
    type: SET_APP_QR_CAMERA_STATUS,
    qrCameraStatus: qrCameraStatus,
  };
}
