import PropTypes from 'prop-types';

import type {Action} from '../actions/types';
import {
  SET_VERIFICATION_STATUS,
  INIT_VERIFICATION,
  SET_VERIFICATION_ROUTE,
} from '../actions/verification';

import {VerificationStatus, VerificationRoute} from '../constants/verification';

// var types
export type State = {
  verificationStatus: PropTypes.string,
  verificationInit: PropTypes.bool,
  verificationRoute: PropTypes.number,
};

// init states
const initialState = {
  verificationStatus: VerificationStatus.NA,
  verificationInit: false,
  verificationRoute: VerificationRoute.DEFAULT,
};

// action reducer
export default function(state: State = initialState, action: Action): State {
  switch (action.type) {
    case SET_VERIFICATION_STATUS:
      return {
        ...state,
        verificationStatus: action.verificationStatus,
      };
    case INIT_VERIFICATION:
      return {
        ...state,
        verificationInit: action.verificationInit,
      };
    case SET_VERIFICATION_ROUTE:
      return {
        ...state,
        verificationRoute: action.verificationRoute,
      };
  }
  return state;
}
