import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Routes from './routes';

const history = createBrowserHistory();
const pathname = window.location.pathname.split('/');
const eanParam = (pathname[1] === 'ean' ? pathname[2] : '');
const eanRegExp = /^\d{13}$/g;
const uuidParam = (pathname[1] === 'uuid' ? pathname[2] : '');
const uuidRegExp = /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/g
const ean = eanRegExp.test(eanParam) ? eanParam : '';
const uuid = uuidRegExp.test(uuidParam) ? uuidParam : '';

function App() {
  return (
    <Router history={history}>
      <Routes uuid={uuid} ean={ean} />
    </Router>
  );
}

export default App;
