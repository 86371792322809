import React from "react";
import {Switch} from "react-router-dom";
import Route from "./Route";
import ItemInfo from "../scenes/ItemInfo";
import BarcodeInfo from "../scenes/BarcodeInfo";
import OriginInfo from "../scenes/OriginInfo";
import ItemInfoExt from "../scenes/ItemInfoExt";
import ContactUs from "../scenes/ContactUs";
import NotFound from "../scenes/NotFound";

export default function Routes({ean, uuid}) {
  const isCheckInit = ean || uuid;
  return (
    <Switch>
      <Route path="/uuid/:uuid" exact component={ItemInfo} isCheckInit={isCheckInit} />
      <Route path="/ean/:ean" exact component={BarcodeInfo} isCheckInit={isCheckInit} />
      <Route path="/origin-info" component={OriginInfo} isCheckInit={isCheckInit} />
      <Route path="/item-info-ext" component={ItemInfoExt} isCheckInit={isCheckInit} />
      <Route path="/contact-us" component={ContactUs} isCheckInit={isCheckInit} />
      <Route component={NotFound} isCheckInit />
    </Switch>
  );
}
