import Api from '../services/api';

// send customer response from contact us component
export function sendCustomerResponse(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const bodyString = {
      verificationId: state.item.itemData.verificationId,
      endUserId: null,
      name: data.name,
      description: data.description,
      telephone: data.phone,
      email: data.email,
      reason: data.issue,
      type: data.type,
      status: 'OPEN',
    };
    //console.log(bodyString)
    const result = await Api.sendCustomerResponse(bodyString);
    return result;
  };
}
