import Api from '../services/api';
import Merge from '../helpers/merge';

import {DepartmentModelInitialState} from '../models/department';

// request Department data over API
export function fetchDepartmentData(departmentId: string) {
  return async (dispatch, getState) => {
    const data = await Api.getDepartmentById(departmentId);
    return Merge.deep(DepartmentModelInitialState, data);
  };
}
