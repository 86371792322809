// eslint-disable-next-line
const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

const getYouTubeVideoID = (urls) => {
  let youTubeIds = [];
  for (let i = 0; i < urls.length; ++i) {
    const result = urls[i].match(rx);
    if (result[1]) {
      youTubeIds.push(result[1]);
    }
  }
  return youTubeIds;
}

export default getYouTubeVideoID;
