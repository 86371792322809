import Api from '../services/api';
import {
  VerificationStatus,
  VerificationResultReasonStatus,
} from '../constants/verification';

export const SET_VERIFICATION_STATUS = 'SET_VERIFICATION_STATUS';
export const SET_VERIFICATION_ROUTE = 'SET_VERIFICATION_ROUTE';
export const INIT_VERIFICATION = 'INIT_VERIFICATION';
export const STORAGE_KEY = 'OO:VERIFICATION:DATA';

// set init flag of verification
export function initVerification(verificationInit: boolean): Action {
  console.log('verification::initVerification: ', verificationInit);
  return {
    type: INIT_VERIFICATION,
    verificationInit: verificationInit,
  };
}

// set verification status action
export function setVerificationStatus(verificationStatus: string): Action {
  console.log('verification::setVerificationStatus: ', verificationStatus);
  return {
    type: SET_VERIFICATION_STATUS,
    verificationStatus: verificationStatus,
  };
}

// set verification route action
export function setVerificationRoute(verificationRoute: number): Action {
  console.log('verification::setVerificationRoute: ', verificationRoute);
  return {
    type: SET_VERIFICATION_ROUTE,
    verificationRoute: verificationRoute,
  };
}

// get all info about verification
export function fetchAllVerrification(itemId: string) {
  return async (dispatch, getState) => {
    const result = await Api.getAllVerrification(itemId);
    return result;
  };
}

// check init flag
export function isVerificationInit() {
  console.log('verification::isVerificationInit');
  return (dispatch, getState) => {
    const state = getState();
    return state.verification.verificationInit;
  };
}

// verfication workflow
export function updateVerification(verStatus: string) {
  return async (dispatch, getState) => {
    // set local status
    dispatch(setVerificationStatus(verStatus));

    // update verification if needed
    switch (verStatus) {
      case VerificationStatus.INITIALIZED: // init flag set - start point of workflow
        // init verification on API side
        dispatch(initVerification(true));
        dispatch(saveVerificationObject());
        return true;
      case VerificationStatus.NA: // init flag set - end point of workflow
        dispatch(initVerification(false));
        await localStorage.removeItem(STORAGE_KEY); // remove verification object
        return true;
      default:
        // work with API
        try {
          const bodyData = await localStorage.getItem(STORAGE_KEY);
          if (bodyData !== null) {
            let dataObject = JSON.parse(bodyData);
            // change verification status for current
            const state = getState();

            let verificationStatus = null;
            let verificationResult = null;
            let verificationResultReason = null;
            // set proper status / result / reason
            switch (state.verification.verificationStatus) {
              case VerificationStatus.INITIALIZED:
                verificationStatus = VerificationResultReasonStatus.INITIALIZED;
                break;
              case VerificationStatus.CANCELED_BY_CUSTOMER:
                verificationStatus =
                  VerificationResultReasonStatus.CANCELED_BY_CUSTOMER;
                break;
              case VerificationStatus.ON_VERIFICATION:
                verificationStatus =
                  VerificationResultReasonStatus.ON_VERIFICATION;
                break;
              case VerificationStatus.COUNTERFEIT_NOT_PRESENT:
                verificationStatus = VerificationResultReasonStatus.COMPLETED;
                verificationResult = VerificationResultReasonStatus.COUNTERFEIT;
                verificationResultReason =
                  VerificationResultReasonStatus.INEXISTENT_ITEM;
                break;
              case VerificationStatus.COUNTERFEIT_NO_TRANSACTIONS:
                verificationStatus = VerificationResultReasonStatus.COMPLETED;
                verificationResult = VerificationResultReasonStatus.COUNTERFEIT;
                verificationResultReason =
                  VerificationResultReasonStatus.NO_TRANSACTIONS;
                break;
              case VerificationStatus.COUNTERFEIT_DUPLICATED:
                verificationStatus = VerificationResultReasonStatus.COMPLETED;
                verificationResult = VerificationResultReasonStatus.COUNTERFEIT;
                verificationResultReason =
                  VerificationResultReasonStatus.ITEM_DUPLICATED;
                break;
              case VerificationStatus.POSSIBLE_COUNTERFEIT:
                verificationStatus = VerificationResultReasonStatus.COMPLETED;
                verificationResult =
                  VerificationResultReasonStatus.POSSIBLE_COUNTERFEIT;
                break;
              case VerificationStatus.ORIGINAL:
                verificationStatus = VerificationResultReasonStatus.COMPLETED;
                verificationResult = VerificationResultReasonStatus.ORIGINAL;
                break;
            }
            // update status / result / reason
            dataObject.verificationData.status = verificationStatus;
            dataObject.verificationData.result = verificationResult;
            dataObject.verificationData.resultReason = verificationResultReason;

            dataObject.verificationData.algorithmResolutionPath =
              state.verification.verificationRoute;
            // API request
            await Api.updateVerification(
              dataObject.verificationId,
              dataObject.verificationData,
            );
          }
        } catch (e) {
          console.log(e);
        }
        // if any status is set over API, then we should
        // update local status to NA and reset init flag as well
        dispatch(updateVerification(VerificationStatus.NA));
        return true;
    }
  };
}

// save object to local storage
export function saveVerificationObject() {
  console.log('verification::saveVerificationObject');
  return async (dispatch, getState) => {
    const state = getState();
    // build body for API
    const dataObject = {
      verificationId: state.item.itemData.verificationId,
      verificationData: {
        operator: 'REPLACE', // this is only one supported for now
        status: '', // need to be set while API call
        result: null, // need to be set while API call OR null
        resultReason: null, // need to be set while API call OR null
        algorithmResolutionPath: 0,
      },
    };
    await localStorage.setItem(STORAGE_KEY, JSON.stringify(dataObject));
  };
}

// if we have saved verification object when app was closed in prev state
export function prevVerificationFinishIfNeeded() {
  console.log('verification::prevVerificationFinishIfNeeded');

  return async (dispatch, getState) => {
    try {
      const value = await localStorage.getItem(STORAGE_KEY);
      console.log('verification::prevVerificationFinishIfNeeded', value);

      if (value !== null) {
        let dataObject = JSON.parse(value);
        // change verification status to cancel
        dataObject.verificationData.status =
          VerificationStatus.CANCELED_BY_CUSTOMER;
        await Api.updateVerification(
          dataObject.verificationId,
          dataObject.verificationData,
        );
      }
    } catch (e) {
      console.log(e);
    }
    // remove verification object
    await localStorage.removeItem(STORAGE_KEY);
  };
}
