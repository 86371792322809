export default {
  containerItem: {
    paddingTop: 10,
  },
  title: {
    paddingLeft: 25,
    paddingRight: 25,
    textAlign: 'center',
  },
  loading: {
    textAlign: 'center',
  },
};
