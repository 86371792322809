import PropTypes from 'prop-types';
//
// model for Barcode
//
export const BarcodeModelInitialState = {
  id: '',
  value: '',
  type: '',
  product_name: '',
  info: '',
  images: [],
  creation_timestamp: '',
  Country: {
    id: '',
    code: '',
    name: '',
    prefix: [],
  },
  Brand: {
    id: '',
    code: '',
    name: '',
  },
  Category: {
    id: '',
    code: '',
    name: '',
  },
  Product: {
    id: '',
    name: '',
    country_name: '',
    category_name: '',
  },
};

export const BarcodeModelState = {
  id: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  product_name: PropTypes.string,
  info: PropTypes.string,
  images: PropTypes.array,
  creation_timestamp: PropTypes.string,
  Country: {
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    prefix: PropTypes.array,
  },
  Brand: {
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
  },
  Category: {
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
  },
  Product: {
    id: PropTypes.string,
    name: PropTypes.string,
    country_name: PropTypes.string,
    category_name: PropTypes.string,
  },
};
