// TODO move API DOMAIN to globals
// this file conains default domains and values
const ConfigConstants = {
  MAX_PHOTO_COUNT: 3,
  // old data
  //PHP_API_URL: 'https://udev-mobile.onlyoriginal.com/p/', // PHP API
  //PHP_API_URL: 'https://upreprod-mobile.onlyoriginal.com/p/', // PHP API
  //JAVA_API_URL: 'https://udev-mobile.onlyoriginal.com/j/api/v2/', // JAVA API
  //JAVA_API_URL: 'https://pp-api.onlyoriginal.com/api/v2/', // JAVA API
  //JAVA_API_URL: 'https://upreprod-mobile.onlyoriginal.com/j/api/v2/', // JAVA API
  OO_DOMAIN: 'onlor.co',
  //API_DOMAIN: 'prod-mobile.onlyoriginal.com', //upreprod-mobile.onlyoriginal.com
  API_DOMAIN: 'upreprod-mobile.onlyoriginal.com',
  //API_DOMAIN: "udev-mobile.onlyoriginal.com",
  PHP_API_PATH: '/p/',
  JAVA_API_PATH: '/j/api/v2/',
  APP_ID: 'WebApp@2.0.0'
};

export default ConfigConstants;
