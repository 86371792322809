import {combineReducers} from 'redux';

import app from './app';
import item from './item';
import product from './product';
import statistics from './statistics';
import verification from './verification';

// all in one
export default combineReducers({
  app,
  item,
  statistics,
  verification,
  product,
});
