import ConfigConstants from '../constants/config';

const Config = {
  getOODomain,
  getJavaApiUrl,
  getPhpApiUrl,
};

function getOODomain() {
  return ConfigConstants.OO_DOMAIN;
}

// TODO
function getPhpApiUrl() {
  return `https://${ConfigConstants.API_DOMAIN}${ConfigConstants.PHP_API_PATH}`;
}

// TODO
function getJavaApiUrl() {
  return `https://${ConfigConstants.API_DOMAIN}${ConfigConstants.JAVA_API_PATH}`;
}

export default Config;
