import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Barcode from 'react-barcode';
import {connect} from 'react-redux';

import {
  Container, Row, Col, Spinner, ListGroup, ListGroupItem
} from 'reactstrap';
import { Slide } from 'react-slideshow-image';

import BarcodeHelper from '../../helpers/barcode';
import Translate from '../../helpers/translate';

import {fetchBarcodeData} from '../../actions/barcode';
import {fetchImageData} from '../../actions/image';
import {fetchProductData} from '../../actions/product';
import {fetchDepartmentData} from '../../actions/department';
import styles from './styles';

class BarcodeInfo extends Component {
  static propTypes = {
    fetchBarcodeData: PropTypes.func,
    fetchProductData: PropTypes.func,
    fetchDepartmentData: PropTypes.func,
    fetchImageData: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.codeInfo = {
      countryName: '',
      categoryName: '',
      productName: '',
      manufacturerName: '',
      manufacturerCode: '',
      productCode: '',
      codeIsValid: false,
      info: '',
      images: [],
      attributes: [],
    };
    this.infoData = [];

    this.productData = {};
    this.departmentData = {};

    this.state = {
      infoDataSource: this.infoData,
      images: [], // images for product
      imageLoading: false, // image loading flag
      loading: false, // content is loading, not refreshing
      productTemplate: '',
    };
  }

  // lifecycle
  async componentDidMount() {
    document.title = Translate.TITLE_SCAN_RESULT;
    // fetch data when component is opening
    await this.fetchData();
  }

  // fetch data from API
  fetchData = async () => {
    const { match: { params } } = this.props;

    this.setState({loading: true});

    let productDataImages = [];

    // process ean13 code
    try {
      const barcodeInfo = await this.props.fetchBarcodeData(params.ean);

      this.codeInfo.categoryName =
        barcodeInfo.Product.category_name || barcodeInfo.Category.name;
      this.codeInfo.manufacturerName = barcodeInfo.Brand.name;
      this.codeInfo.productName =
        barcodeInfo.Product.name || barcodeInfo.product_name;
      this.codeInfo.images = barcodeInfo.images || [];
      this.codeInfo.attributes = barcodeInfo.Attributes || [];
      console.log('codeInfo', this.codeInfo);
      // additional info JSON
      this.codeInfo.info = barcodeInfo.info || '';

      if (barcodeInfo.Product.id) {
        this.productData = await this.props.fetchProductData(
          barcodeInfo.Product.id,
        );
        this.departmentData = await this.props.fetchDepartmentData(
          this.productData.departmentId,
        );
        productDataImages = this.productData.images;
      }
    } catch (e) {
      console.log(e);
    }

    const countryCode = BarcodeHelper.getCountryCode(params.ean);

    this.codeInfo.countryName = Translate[`TEXT_COUNTRY_NAME_${countryCode}`];
    this.codeInfo.codeIsValid = BarcodeHelper.codeIsValid(params.ean);
    this.codeInfo.manufacturerCode = BarcodeHelper.getManufacturerCode(
      params.ean,
    );
    this.codeInfo.productCode = BarcodeHelper.getProductCode(params.ean);

    // add info to view
    this.populateInfoData();

    this.setState({loading: false});

    if (productDataImages.length > 0 || this.codeInfo.images.length > 0) {
      this.setState({imageLoading: true});

      let images = [];
      // fetch data by imageId
      for (let i = 0; i < productDataImages.length; i++) {
        // fetch image data
        let object = await this.props.fetchImageData(productDataImages[i]);
        images.push({url: object.data});
      }
      // images like http://....
      for (let i = 0; i < this.codeInfo.images.length; i++) {
        images.push({url: this.codeInfo.images[i]});
      }

      this.setState({images: images}, () => {
        setTimeout(() => {
          // do little timeout for nice UI
          this.setState({imageLoading: false});
        }, 1000);
      });
    }
  }

  populateInfoData = () => {
    const { match: { params } } = this.props;

    this.infoData = [];

    if (this.codeInfo.productName) {
      this.infoData.push(
        {
          text: Translate.TEXT_PRODUCT_NAME.toUpperCase(),
          listStyle: styles.containerTitle,
          textStyle: styles.txtTitle,
          noBorder: false,
        },
        {
          text: this.codeInfo.productName,
          listStyle: styles.containerItem,
          textStyle: styles.txtItem,
          noBorder: true,
        },
      );
    }

    if (this.codeInfo.categoryName) {
      this.infoData.push(
        {
          text: Translate.TEXT_PRODUCT_CATEGORY.toUpperCase(),
          listStyle: styles.containerTitle,
          textStyle: styles.txtTitle,
          noBorder: false,
        },
        {
          text: this.codeInfo.categoryName,
          listStyle: styles.containerItem,
          textStyle: styles.txtItem,
          noBorder: true,
        },
      );
    }

    let productInfo = {};
    try {
      productInfo = JSON.parse(this.productData.info);
    } catch (e) {}
    // extended info
    if (Object.keys(productInfo).length > 0) {
      for (let key in productInfo) {
        this.infoData.push(
          {
            text: key.toUpperCase(),
            listStyle: styles.containerTitle,
            textStyle: styles.txtTitle,
            noBorder: false,
          },
          {
            text: productInfo[key],
            listStyle: styles.containerItem,
            textStyle: styles.txtItem,
            noBorder: true,
          },
        );
      }
    }

    // additional info from barcode attributes
    this.codeInfo.attributes.forEach(data => {
      this.infoData.push(
        {
          text: data.name.toUpperCase(),
          listStyle: styles.containerTitle,
          textStyle: styles.txtTitle,
          noBorder: false,
        },
        {
          text: data.value,
          listStyle: styles.containerItem,
          textStyle: styles.txtItem,
          noBorder: true,
        },
      );
    });

    if (this.codeInfo.manufacturerName) {
      this.infoData.push(
        {
          text: Translate.TEXT_MANUFACTURER_NAME.toUpperCase(),
          listStyle: styles.containerTitle,
          textStyle: styles.txtTitle,
          noBorder: false,
        },
        {
          text: this.codeInfo.manufacturerName,
          listStyle: styles.containerItem,
          textStyle: styles.txtItem,
          noBorder: true,
        },
      );
    }

    if (this.departmentData.name) {
      this.infoData.push(
        {
          text: Translate.TEXT_DEPARTMENT.toUpperCase(),
          listStyle: styles.containerTitle,
          textStyle: styles.txtTitle,
          noBorder: false,
        },
        {
          text: `${this.departmentData.name} ${this.departmentData.address}`,
          listStyle: styles.containerItem,
          textStyle: styles.txtItem,
          noBorder: true,
        },
      );
    }

    this.infoData.push(
      {
        text: Translate.TEXT_CODE.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
        noBorder: false,
      },
      {
        text: params.ean,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
        noBorder: true,
      },
      {
        text: Translate.TEXT_COUNTRY_ORIGIN.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
        noBorder: false,
      },
      {
        text: this.codeInfo.countryName,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
        noBorder: true,
      },
      {
        text: Translate.TEXT_MANUFACTURER_CODE.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
        noBorder: false,
      },
      {
        text: this.codeInfo.manufacturerCode,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
        noBorder: true,
      },
      {
        text: Translate.TEXT_PRODUCT_CODE.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
        noBorder: false,
      },
      {
        text: this.codeInfo.productCode,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
        noBorder: true,
      },
      {
        text: Translate.TEXT_CODE_IS_OK.toUpperCase(),
        listStyle: styles.containerTitle,
        textStyle: styles.txtTitle,
        noBorder: false,
      },
      {
        text: this.codeInfo.codeIsValid
          ? Translate.TEXT_YES
          : Translate.TEXT_NO,
        listStyle: styles.containerItem,
        textStyle: styles.txtItem,
        noBorder: true,
      },
    );

    //
    // additional info
    //
    let additionalInfo = {};
    try {
      additionalInfo = JSON.parse(this.codeInfo.info);
    } catch (e) {
      /*do nothing*/
    }

    if (Object.keys(additionalInfo).length > 0) {
      for (let key in additionalInfo) {
        this.infoData.push(
          {
            text: key.toUpperCase(),
            listStyle: styles.containerTitle,
            textStyle: styles.txtTitle,
            noBorder: false,
          },
          {
            text: additionalInfo[key],
            listStyle: styles.containerItem,
            textStyle: styles.txtItem,
            noBorder: true,
          },
        );
      }
    }

    this.infoData.push(
      {
        text: Translate.TEXT_BARCODE.toUpperCase(),
        listStyle: styles.containerTitle,
        noBorder: false,
      },
      {
        element: (
          <Barcode
            value={params.ean}
            format="CODE128"
            background={'transparent'}
          />
        ),
        listStyle: styles.containerItem,
        noBorder: true,
      },
    );

    this.setState({
      infoDataSource: this.infoData,
    });
  }

  // render main content
  renderContent = () => {
    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: true,
      autoplay: false,
    }
    return (
      <Col>
        <Row>
          <Col style={styles.loading}>
            {this.state.imageLoading && (<Spinner animation="grow" color="primary" />)}
            {!this.state.imageLoading && this.state.images.length > 0 && (
              <Slide {...properties}>
              {this.state.images.map((each, index) => <img key={index} style={styles.imageSlide} src={each.url} alt=""/>)}
              </Slide>
            )}
          </Col>
        </Row>
        <ListGroup>
        {this.state.infoDataSource.map((info, index) => {
          const element = info.element ? info.element : <span style={info.textStyle}>{info.text}</span>;
          return <ListGroupItem key={index} style={info.listStyle}>{element}</ListGroupItem>
        })}
        </ListGroup>
      </Col>
    );
  }

  render() {
    return (
      <Container>
        {this.state.loading && (
          <Col style={styles.loading}>
            <Spinner animation="border" role="status" color="primary" />
          </Col>
        )}
        {!this.state.loading && this.renderContent()}
      </Container>
    );
  }
}

function bindAction(dispatch) {
  return {
    fetchBarcodeData: barcode => dispatch(fetchBarcodeData(barcode)),
    fetchProductData: productId => dispatch(fetchProductData(productId)),
    fetchDepartmentData: departmentId =>
      dispatch(fetchDepartmentData(departmentId)),
    fetchImageData: imageId => dispatch(fetchImageData(imageId)),
  };
}

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  bindAction,
)(BarcodeInfo);
