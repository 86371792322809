const BarcodeHelper = {
  getCountryCode,
  getCountryISO2Codes,
  codeIsValid,
  getManufacturerCode,
  getProductCode,
};

function getCountryISO2Codes() {
  const codes = {
    GB: ['500', '501', '502', '503', '504', '505', '506', '507', '508', '509'],
    BE_LU: [
      '540',
      '541',
      '542',
      '543',
      '544',
      '545',
      '546',
      '547',
      '548',
      '549',
    ],
    DK: ['570', '571', '572', '573', '574', '575', '576', '577', '578', '579'],
    FI: ['640', '641', '642', '643', '644', '645', '646', '647', '648', '649'],
    NO: ['700', '701', '702', '703', '704', '705', '706', '707', '708', '709'],
    SE: ['730', '731', '732', '733', '734', '735', '736', '737', '738', '739'],
    CH: ['760', '761', '762', '763', '764', '765', '766', '767', '768', '769'],
    ES: ['840', '841', '842', '843', '844', '845', '846', '847', '848', '849'],
    NL: ['870', '871', '872', '873', '874', '875', '876', '877', '878', '879'],
    AU: ['930', '931', '932', '933', '934', '935', '936', '937', '938', '939'],
    NZ: ['940', '941', '942', '943', '944', '945', '946', '947', '948', '949'],
    BG: ['380'],
    SI: ['383'],
    HR: ['385'],
    BA: ['387'],
    TW: ['471'],
    EE: ['474'],
    LV: ['475'],
    AZ: ['476'],
    LT: ['477'],
    UZ: ['478'],
    LK: ['479'],
    PH: ['480'],
    BY: ['481'],
    UA: ['482'],
    MD: ['484'],
    AM: ['485'],
    GE: ['486'],
    KZ: ['487'],
    HK: ['489'],
    GR: ['520'],
    LB: ['528'],
    CY: ['529'],
    MK: ['531'],
    MT: ['535'],
    IE: ['539'],
    PT: ['560'],
    IS: ['569'],
    PL: ['590'],
    RO: ['594'],
    HU: ['599'],
    BH: ['608'],
    MU: ['609'],
    MA: ['611'],
    DZ: ['613'],
    KE: ['616'],
    TN: ['619'],
    SY: ['621'],
    EG: ['622'],
    LY: ['624'],
    JO: ['625'],
    IR: ['626'],
    KW: ['627'],
    SA: ['628'],
    AE: ['629'],
    IL: ['729'],
    GT: ['740'],
    SV: ['741'],
    HN: ['742'],
    NI: ['743'],
    CR: ['744'],
    PA: ['745'],
    DO: ['746'],
    MX: ['750'],
    VE: ['759'],
    CO: ['770'],
    UY: ['773'],
    PE: ['775'],
    BO: ['777'],
    AR: ['779'],
    CL: ['780'],
    PY: ['784'],
    EC: ['786'],
    CU: ['850'],
    SK: ['858'],
    CZ: ['859'],
    YU: ['860'],
    KP: ['867'],
    TR: ['869'],
    KR: ['880'],
    TH: ['885'],
    SG: ['888'],
    IN: ['890'],
    VN: ['893'],
    ID: ['899'],
    MY: ['955'],
    MO: ['958'],
    US_CA: [
      '000',
      '001',
      '002',
      '003',
      '004',
      '005',
      '006',
      '007',
      '008',
      '009',
      '010',
      '011',
      '012',
      '013',
      '014',
      '015',
      '016',
      '017',
      '018',
      '019',
      '020',
      '021',
      '022',
      '023',
      '024',
      '025',
      '026',
      '027',
      '028',
      '029',
      '030',
      '031',
      '032',
      '033',
      '034',
      '035',
      '036',
      '037',
      '038',
      '039',
      '040',
      '041',
      '042',
      '043',
      '044',
      '045',
      '046',
      '047',
      '048',
      '049',
      '050',
      '051',
      '052',
      '053',
      '054',
      '055',
      '056',
      '057',
      '058',
      '059',
      '060',
      '061',
      '062',
      '063',
      '064',
      '065',
      '066',
      '067',
      '068',
      '069',
      '070',
      '071',
      '072',
      '073',
      '074',
      '075',
      '076',
      '077',
      '078',
      '079',
      '080',
      '081',
      '082',
      '083',
      '084',
      '085',
      '086',
      '087',
      '088',
      '089',
      '090',
      '091',
      '092',
      '093',
      '094',
      '095',
      '096',
      '097',
      '098',
      '099',
      '100',
      '101',
      '102',
      '103',
      '104',
      '105',
      '106',
      '107',
      '108',
      '109',
      '110',
      '111',
      '112',
      '113',
      '114',
      '115',
      '116',
      '117',
      '118',
      '119',
      '120',
      '121',
      '122',
      '123',
      '124',
      '125',
      '126',
      '127',
      '128',
      '129',
      '130',
      '131',
      '132',
      '133',
      '134',
      '135',
      '136',
      '137',
      '138',
      '139',
    ],
    FR: [
      '300',
      '301',
      '302',
      '303',
      '304',
      '305',
      '306',
      '307',
      '308',
      '309',
      '310',
      '311',
      '312',
      '313',
      '314',
      '315',
      '316',
      '317',
      '318',
      '319',
      '320',
      '321',
      '322',
      '323',
      '324',
      '325',
      '326',
      '327',
      '328',
      '329',
      '330',
      '331',
      '332',
      '333',
      '334',
      '335',
      '336',
      '337',
      '338',
      '339',
      '340',
      '341',
      '342',
      '343',
      '344',
      '345',
      '346',
      '347',
      '348',
      '349',
      '350',
      '351',
      '352',
      '353',
      '354',
      '355',
      '356',
      '357',
      '358',
      '359',
      '360',
      '361',
      '362',
      '363',
      '364',
      '365',
      '366',
      '367',
      '368',
      '369',
      '370',
      '371',
      '372',
      '373',
      '374',
      '375',
      '376',
      '377',
      '378',
      '379',
    ],
    DE: [
      '400',
      '401',
      '402',
      '403',
      '404',
      '405',
      '406',
      '407',
      '408',
      '409',
      '410',
      '411',
      '412',
      '413',
      '414',
      '415',
      '416',
      '417',
      '418',
      '419',
      '420',
      '421',
      '422',
      '423',
      '424',
      '425',
      '426',
      '427',
      '428',
      '429',
      '430',
      '431',
      '432',
      '433',
      '434',
      '435',
      '436',
      '437',
      '438',
      '439',
      '440',
    ],
    JP: ['490', '491', '492', '493', '494', '495', '496', '497', '498', '499'],
    RU: ['460', '461', '462', '463', '464', '465', '466', '467', '468', '469'],
    ZA: ['600', '601'],
    CN: ['690', '691', '692', '693', '694', '695'],
    BR: ['789', '790'],
    IT: [
      '800',
      '801',
      '802',
      '803',
      '804',
      '805',
      '806',
      '807',
      '808',
      '809',
      '810',
      '811',
      '812',
      '813',
      '814',
      '815',
      '816',
      '817',
      '818',
      '819',
      '820',
      '821',
      '822',
      '823',
      '824',
      '825',
      '826',
      '827',
      '828',
      '829',
      '830',
      '831',
      '832',
      '833',
      '834',
      '835',
      '836',
      '837',
      '838',
      '839',
    ],
    AT: [
      '900',
      '901',
      '902',
      '903',
      '904',
      '905',
      '906',
      '907',
      '908',
      '909',
      '910',
      '911',
      '912',
      '913',
      '914',
      '915',
      '916',
      '917',
      '918',
      '919',
    ],
  };

  return codes;
}

function convertToArray(ean13: string) {
  return ean13.split('');
}

function getCountryCode(ean13: string) {
  const countryDigit = convertToArray(ean13)
    .slice(0, 3)
    .join('')
    .toString();
  const codes = getCountryISO2Codes();

  let result = 'NA';

  for (let isoCode in codes) {
    if (codes[isoCode].indexOf(countryDigit) !== -1) {
      result = isoCode;
      break;
    }
  }

  return result;
}

function codeIsValid(ean13: string) {
  const eanData = convertToArray(ean13);

  let sum = 0;

  // do not use last digit while calculate
  for (let i = 0; i < eanData.length - 1; i++) {
    if (i % 2 === 0) {
      sum += Number(eanData[i]);
    } else {
      sum += Number(eanData[i]) * 3;
    }
  }
  const mod = sum % 10;
  const checkDigit = mod ? 10 - mod : 0;

  return Number(eanData[eanData.length - 1]) === Number(checkDigit);
}

function getManufacturerCode(ean13: string) {
  // from 4 till 7 pos
  return convertToArray(ean13)
    .slice(3, 7)
    .join('');
}

function getProductCode(ean13: string) {
  // from 8 till 12 pos
  return convertToArray(ean13)
    .slice(7, 12)
    .join('');
}

export default BarcodeHelper;
