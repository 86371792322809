import PropTypes from 'prop-types';
//
// model for statistics
//
export const StatModelInitialState = {
  verificationId: '',
  itemManufactured: false,
  allVerificationsWithinTolerableRadius: false,
  verifiedByApplicationId: false,
  itemDismissed: false,
  itemDeparted: false,
  todayVerificationsQuantity: 0,
  verificationsQuantity: 0,
  lastVerificationTimestamp: '',
  lastVerificationGps: '',
};

export const StatModelState = {
  verificationId: PropTypes.string,
  itemManufactured: PropTypes.bool,
  itemDeparted: PropTypes.bool,
  allVerificationsWithinTolerableRadius: PropTypes.bool,
  verifiedByApplicationId: PropTypes.bool,
  itemDismissed: PropTypes.bool,
  todayVerificationsQuantity: PropTypes.number,
  verificationsQuantity: PropTypes.number,
  lastVerificationTimestamp: PropTypes.string,
  lastVerificationGps: PropTypes.string,
};
