import React from "react";
import { withRouter } from 'react-router-dom';
import Translate from '../../helpers/translate';
import getYouTubeVideoID from '../../helpers/youtube'

import {
  Container, Row, Col, Button,
} from 'reactstrap';
import styles from './styles';

class OriginInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: [Translate.INFO_ORIGINAL_ITEM_TEXT],
      showDetails: true,
      image: 'alert',
      showContactUs: true,
      videoIDs: [],
    }
  }

  componentDidMount() {
    this.setStates();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setStates();
    }
  }

  setStates = async () => {
    const {image, text, videoURL, showDetails, showContactUs} = this.props.location.state || {};

    let videoIDs = [];
    if (videoURL !== undefined && videoURL.length > 0) {
      videoIDs = getYouTubeVideoID(videoURL);
    }

    this.setState({
      image: (image !== undefined ? image : this.state.image),
      text: (text !== undefined ? text : this.state.text),
      videoIDs,
      showDetails: (showDetails !== undefined ? showDetails : this.state.showDetails),
      showContactUs: (showContactUs !== undefined ? showContactUs : this.state.showContactUs),
    });
  }

  itemInfoExt = () => {
    this.props.history.replace('/item-info-ext');
  }

  contactUs = () => {
    this.props.history.replace('/contact-us');
  }

  render() {
    const {image: imageProps, text, videoIDs, showDetails, showContactUs} = this.state;

    let image = null;
    switch (imageProps) {
      case 'alert':
        image = require('../../img/alert.png');
        break;
      case 'stop':
        image = require('../../img/stop.png');
        break;
      case 'star':
        image = require('../../img/star.png');
        break;
      case 'dialog':
        image = require('../../img/dialog.png');
        break;
      case 'horn':
        image = require('../../img/horn.png');
        break;
      case 'monolog':
        image = require('../../img/monolog.png');
        break;
      case 'original':
        image = require('../../img/original.png');
        break;
      default:
        image = require('../../img/alert.png');
    }
    let mainTxt = '';
    let subTxt = '';

    if (Array.isArray(text)) {
      mainTxt = text[0];
      subTxt =
        typeof text[1] !== undefined ? text[1] : '';
    } else {
      mainTxt = text;
    }
    const imageStyle = videoIDs.length > 0 ? styles.imageSmall : styles.image;

    return (
      <Container>
        <Col style={styles.imageCol}>
          <img src={image} style={imageStyle} alt="" />
        </Col>
        <Col>
          <div style={styles.mainTxt}>{mainTxt}</div>
          {subTxt !== '' && (
            <div style={styles.subTxt}>{subTxt}</div>
          )}
        </Col>
        {videoIDs.length > 0 && (
        <Col style={styles.imageCol}>
          <iframe title="YouTube" id="ytplayer" type="text/html" width="90%"
            src={`//www.youtube.com/embed/?autoplay=0&playlist=${videoIDs.join(',')}`}
            frameBorder="0" />
        </Col>
        )}
        {showDetails && (
          <Row style={styles.buttonRow}>
            <Button
              block
              color="success"
              onClick={() => {
                this.itemInfoExt();
              }}>
              {Translate.TEXT_MORE}
            </Button>
          </Row>
        )}
        {showContactUs && (
          <Row style={styles.buttonRow}>
            <Button
              block
              color="primary"
              onClick={() => {
                this.contactUs();
              }}>
              {Translate.BUTTON_TEXT_CONTACT_US}
            </Button>
          </Row>
        )}
      </Container>
    );
  }
}

export default withRouter(OriginInfo);
