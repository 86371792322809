import PropTypes from 'prop-types';
//
// model for product
//
export const ProductModelInitialState = {
  id: '',
  departmentId: '',
  barcode: '',
  categoryId: '',
  images: [],
  country: '',
  name: '',
  description: '',
  info: '',
};

export const ProductModelState = {
  id: PropTypes.string,
  departmentId: PropTypes.string,
  barcode: PropTypes.string,
  categoryId: PropTypes.string,
  images: PropTypes.array,
  country: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  info: PropTypes.string,
};
