import PropTypes from 'prop-types';
//
// model for departemnt
//
export const DepartmentModelInitialState = {
  id: '',
  unitId: '',
  country: '',
  name: '',
  address: '',
  gps: '',
  info: '',
};

export const DepartmentModelState = {
  id: PropTypes.string,
  unitId: PropTypes.string,
  country: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  gps: PropTypes.string,
  info: PropTypes.string,
};
