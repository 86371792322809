import PropTypes from 'prop-types';

import type {Action} from '../actions/types';
import {SET_PRODUCT_MEDIA_LINKS} from '../actions/product';

// var types
export type State = {
  productMediaLinks: PropTypes.array,
};

// init states
const initialState = {
  productMediaLinks: [],
};

// action reducer
export default function(state: State = initialState, action: Action): State {
  switch (action.type) {
    case SET_PRODUCT_MEDIA_LINKS:
      return {
        ...state,
        productMediaLinks: action.productMediaLinks,
      };
  }
  return state;
}
