// helper for GPS coords
const Gps = {
  getCoordsFromString,
  roundCoords,
};

// get coords from string like "40.00...,50.00...."
function getCoordsFromString(coordsString, asString = false) {
  const latlng = coordsString.toString().split(',');

  const latitude = Number(latlng[0]);
  const longitude = Number(latlng[1]);

  if (!Number.isNaN(latitude) && !Number.isNaN(longitude)) {
    const coords = roundCoords({latitude: latitude, longitude: longitude});
    if (asString) {
      // return like a string
      return `${coords.latitude},${coords.longitude}`;
    }
    return coords; // return like an object
  }
  return asString ? '' : false;
}

// round coords
function roundCoords(coords) {
  const roundedCoords = {
    latitude: Number(coords.latitude).toFixed(6),
    longitude: Number(coords.longitude).toFixed(6),
  };
  return roundedCoords;
}

export default Gps;
