const Merge = {
  deep,
};

// deep merge of array / object
function deep(...objects) {
  const isObject = obj => obj && typeof obj === 'object';

  return objects.reduce((target, source) => {
    Object.keys(source).map(key => {
      // values
      const targetVal = target[key];
      const sourceVal = source[key];

      // check for types
      if (Array.isArray(targetVal) && Array.isArray(sourceVal)) {
        // if we have an array and the length is not same
        // add more elements then
        if (targetVal.length !== sourceVal.length) {
          for (let i = 0; i < sourceVal.length; i++) {
            if (!targetVal[i]) {
              targetVal.push(targetVal[0]);
            }
          }
        }
        target[key] = Merge.deep(targetVal, sourceVal);
        target[key] = Object.keys(target[key]).map(i => target[key][i]); // to array
        //target[key] = targetVal.concat(...sourceVal)
      } else if (isObject(targetVal) && isObject(sourceVal)) {
        // merge
        target[key] = Merge.deep(targetVal, sourceVal);
      } else {
        target[key] = sourceVal;
      }
    });

    return target;
  }, {});
}

export default Merge;
