import Moment from 'moment';

// helper for Date
const DateUtil = {
  toLocalDate,
  UTCDate,
  todayDate,
};
// from UTC to locale date
function toLocalDate(
  dateString,
  dateStringFormat = 'YYYY-MM-DD HH:mm:ss',
  localDateFormat = 'DD.MM.YYYY HH:mm:ss',
) {
  const date = Moment.utc(dateString, dateStringFormat);
  return date.local().format(localDateFormat);
}

function UTCDate(dateStringFormat = 'DD.MM.YYYY HH:mm:ss') {
  const localDate = new Date();
  return Moment.utc(localDate).format(dateStringFormat);
}

function todayDate(dateStringFormat = 'DD.MM.YYYY HH:mm:ss') {
  const localDate = new Date();
  return Moment(localDate).format(dateStringFormat);
}

export default DateUtil;
