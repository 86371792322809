import PropTypes from 'prop-types';
//
// model for item
//
export const ItemModelInitialState = {
  item: {
    id: '',
    uuid: '',
    status: '',
    name: '',
    country: '',
    departmentId: '',
    productId: '',
    batchId: '',
    info: '',
    images: [],
    manufacturedDate: '',
    barcode: '',
  },
  unit: {
    id: '',
    name: '',
    registeredAddress: '',
    actualAddress: '',
    gps: '',
    creationTimestamp: '',
    info: '',
  },
  batch: {
    id: '',
    uuid: '',
    info: '',
    departmentId: '',
    parentId: '',
    childBatchesIds: [],
    childItemsIds: [],
  },
  department: {
    id: '',
    unitId: '',
    country: '',
    name: '',
    address: '',
    gps: '',
    info: '',
  },
  transactions: [
    {
      id: '',
      type: '',
      gps: '',
      creationTimestamp: '',
    },
  ],
  verificationId: 0,
};
export const ItemModelState = {
  item: {
    id: PropTypes.string,
    uuid: PropTypes.string,
    status: PropTypes.string,
    name: PropTypes.string,
    country: PropTypes.string,
    departmentId: PropTypes.string,
    productId: PropTypes.string,
    batchId: PropTypes.string,
    info: PropTypes.string,
    images: PropTypes.array,
    manufacturedDate: PropTypes.string,
    barcode: PropTypes.string,
  },
  unit: {
    id: PropTypes.string,
    name: PropTypes.string,
    registeredAddress: PropTypes.string,
    actualAddress: PropTypes.string,
    gps: PropTypes.string,
    creationTimestamp: PropTypes.string,
    info: PropTypes.string,
  },
  batch: {
    id: PropTypes.string,
    uuid: PropTypes.string,
    info: PropTypes.string,
    departmentId: PropTypes.string,
    parentId: PropTypes.string,
    childBatchesIds: PropTypes.array,
    childItemsIds: PropTypes.array,
  },
  department: {
    id: PropTypes.string,
    unitId: PropTypes.string,
    country: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    gps: PropTypes.string,
    info: PropTypes.string,
  },
  transactions: [
    {
      id: PropTypes.string,
      gps: PropTypes.string,
      type: PropTypes.string,
      creationTimestamp: PropTypes.string,
    },
  ],
  verificationId: PropTypes.string,
};
